/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 15:28:45 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 15:28:45 
 */
<template>
  <div class="banner">
      <img src="../../../assets/images/banner2.jpg" alt="">
  </div>
</template>

<script>
export default {
  props: ["course"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.banner {
  // margin-top: -30px;
  img{
    width: 100%;
  }
}
</style>