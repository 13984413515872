/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:16:53 
 * @Last Modified by:   liuzhixiang 
 * @Last Modified time: 2021-02-06 14:16:53 
 */
<template>
  <div>
    <!-- <Banner></Banner> -->
    <data-download :type="type"></data-download>
  </div>
</template>

<script>
import dataDownload from "@/components/dataDownload";
import Banner from "./components/banner";
export default {
  data() {
    return {
      type:"download",
    };
  },
  components: {
    dataDownload,Banner
  },
  created() {},
  mounted() {
    
  },
  methods: {
   
  },
};
</script>

<style lang="less" scoped>
</style>