var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"downloadList"},[(
      _vm.list.courses !== undefined &&
        _vm.list.courses.length > 0 &&
        _vm.list.types != 'teacher'
    )?_c('el-row',{attrs:{"gutter":24}},_vm._l((_vm.list.courses),function(li,i){return _c('el-col',{key:i,attrs:{"span":12}},[_c('div',{staticClass:"bgc_ffffff list",on:{"click":function($event){return _vm.goTo(li, _vm.list.types)}}},[_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(li.Name))]),_c('i',{staticClass:"el-icon-d-arrow-right"})]),_c('div',{staticClass:"courseFileList"},_vm._l((li.CourseFileList.slice(0, 3)),function(lic,j){return _c('div',{key:j,staticClass:"listc"},[_c('div',{staticClass:"fileUrl"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(lic.FileImg),expression:"lic.FileImg"}],attrs:{"alt":""}}),_c('div',{staticClass:"tag",class:lic.fileUrlType == 'jpg'
                    ? 'tag1'
                    : lic.fileUrlType == 'docx'
                    ? 'tag2'
                    : lic.fileUrlType == 'png'
                    ? 'tag3'
                    : 'tag2'},[_c('span',[_vm._v(_vm._s(lic.fileUrlType))])])]),_c('div',{staticClass:"fileName"},[_vm._v(" "+_vm._s(lic.FileName)+" ")]),_c('div',{staticClass:"c_F61717 price"},[(lic.Category == 2)?_c('span',{staticClass:"c_3ab57f"},[_vm._v("免费")]):(lic.IsSale != 1 && lic.Category == 0)?_c('span',[_vm._v("课程专属")]):(lic.IsSale != 1 && lic.Category == 1)?_c('span',[_vm._v("VIP专属")]):_c('span',[_vm._v("￥"+_vm._s(_vm._f("priceToFixed")(lic.Price)))])])])}),0)])])}),1):_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('no-data')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }