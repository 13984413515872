<template>
  <div class="downloadList">
    <el-row
      :gutter="24"
      v-if="
        list.courses !== undefined &&
          list.courses.length > 0 &&
          list.types != 'teacher'
      "
    >
      <el-col :span="12" v-for="(li, i) in list.courses" :key="i">
        <div class="bgc_ffffff list" @click="goTo(li, list.types)">
          <div class="title">
            <div>{{ li.Name }}</div>
            <i class="el-icon-d-arrow-right"></i>
          </div>
          <div class="courseFileList">
            <div
              class="listc"
              v-for="(lic, j) in li.CourseFileList.slice(0, 3)"
              :key="j"
            >
              <div class="fileUrl">
                <img v-lazy="lic.FileImg" alt="" />
                <div
                  class="tag"
                  :class="
                    lic.fileUrlType == 'jpg'
                      ? 'tag1'
                      : lic.fileUrlType == 'docx'
                      ? 'tag2'
                      : lic.fileUrlType == 'png'
                      ? 'tag3'
                      : 'tag2'
                  "
                >
                  <span>{{ lic.fileUrlType }}</span>
                </div>
              </div>
              <div class="fileName">
                {{ lic.FileName }}
              </div>
              <div class="c_F61717 price">
                <span v-if="lic.Category == 2" class="c_3ab57f">免费</span>
                <span v-else-if="lic.IsSale != 1 && lic.Category == 0"
                  >课程专属</span
                >
                <span v-else-if="lic.IsSale != 1 && lic.Category == 1"
                  >VIP专属</span
                >
                <span v-else>￥{{ lic.Price | priceToFixed }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-else>
      <el-col :span="24"> 
        <no-data></no-data>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import noData from "@/components/noData";
export default {
  props: ["list"],
  data() {
    return {
      teacherWidth: 0,
    };
  },
  components: {
    noData
  },
  created() {},
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      };
    },
  },
  filters: {
    FileUrlFilters: function(fileUrl) {
      return fileUrl.substring(fileUrl.lastIndexOf(".") + 1);
    },
  },
  mounted() {
    this.list.courses.forEach((item, i) => {
      item.CourseFileList.forEach((it, j) => {
        this.$set(
          it,
          "fileUrlType",
          it.FileUrl.substring(it.FileUrl.lastIndexOf(".") + 1)
        );
      });
    });
  },
  methods: {
    goTo(obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      if (type == "course") {
        this.$router.push({ path: "/course/details", query: ob });
      } else if (type == "teacher") {
        this.$router.push({ path: "/home/teacherDetails", query: obj });
      } else if (type == "download") {
        this.$router.push({ path: "/dataDownload/list", query: ob });
      } else if (type == "question") {
        this.$router.push({ path: "/onlineQuestionBank/details", query: ob });
      }
    },
  },
};
</script>

<style lang="less" scoped>
body .el-scrollbar__wrap {
  overflow-x: hidden;
}
.downloadList {
  margin-top: 40px;
  .el-row {
    .el-col {
      margin-bottom: 30px;
      .list {
        border: 1px solid #eee;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          line-height: 53px;
          background-color: #80b4f9;
          padding: 0 25px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url("../assets/images/1.png");
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      &:nth-child(1) {
        .list {
          .title {
            background-image: url("../assets/images/1.png");
          }
        }
      }
      &:nth-child(2) {
        .list {
          .title {
            background-image: url("../assets/images/2.png");
          }
        }
      }
      &:nth-child(3) {
        .list {
          .title {
            background-image: url("../assets/images/3.png");
          }
        }
      }
      &:nth-child(4) {
        .list {
          .title {
            background-image: url("../assets/images/4.png");
          }
        }
      }
      &:nth-child(5) {
        .list {
          .title {
            background-image: url("../assets/images/5.png");
          }
        }
      }
      &:nth-child(6) {
        .list {
          .title {
            background-image: url("../assets/images/6.png");
          }
        }
      }
      &:nth-child(7) {
        .list {
          .title {
            background-image: url("../assets/images/7.png");
          }
        }
      }
      &:nth-child(8) {
        .list {
          .title {
            background-image: url("../assets/images/8.png");
          }
        }
      }
      .courseFileList {
        padding: 20px 0 25px;
        overflow: hidden;
        .listc {
          width: 30%;
          margin: 0 1.66%;
          float: left;
          border-radius: 3px;
          overflow: hidden;
          box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
          .fileUrl {
            width: 100%;
            // height: 118px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              max-height: 98px;
            }
            .tag {
              position: absolute;
              left: 15px;
              top: -3px;
              width: 24px;
              height: 34px;
              text-align: center;
              background: #e1b979;
              font-size: 12px;
              line-height: 38px;
              color: #ffffff;
              &:before {
                content: "";
                position: absolute;
                left: -6px;
                top: 0;
                border: 3px solid #ca9848;
                border-color: transparent #ca9848 #ca9848 transparent;
              }
              &::after {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 0;
                border: 4px solid #e1b979;
                border-width: 6px 12px;
                border-color: #e1b979 #e1b979 transparent;
              }
            }

            .tag1 {
              background: #e1b979;
              &:before {
                border-color: transparent #e1b979 #e1b979 transparent;
              }
              &::after {
                border-color: #e1b979 #e1b979 transparent;
              }
            }
            .tag2 {
              background: #f07044;
              &:before {
                border-color: transparent #f07044 #f07044 transparent;
              }
              &::after {
                border-color: #f07044 #f07044 transparent;
              }
            }
            .tag3 {
              background: #25bb9b;
              &:before {
                border-color: transparent #25bb9b #25bb9b transparent;
              }
              &::after {
                border-color: #25bb9b #25bb9b transparent;
              }
            }
          }
          .fileName {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 5px;
            font-weight: bold;
            font-size: 15px;
            margin: 10px 0 5px;
          }
          .price {
            font-size: 12px;
            padding: 0 5px;
            margin: 0 0 5px;
          }
        }
      }
    }
  }
}
</style>
